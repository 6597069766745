import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "submission-search"
    }}>{`Submission Search`}</h1>
    <hr />
    <h2 {...{
      "id": "searchsubmissions"
    }}>{`searchSubmissions`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapp -> string = (required) kapp slug for the kapp to search in
* form -> string = form slug to specify submissions for a specific form
* search -> SearchMeta{} = the search metadata derived from the \`SubmissionSearch\`
* get -> boolean = indicates whether the query should use the GET method instead of the default POST method; defaults to false
`}</code></pre>
    <h4 {...{
      "id": "searchmeta"
    }}>{`SearchMeta`}</h4>
    <pre><code parentName="pre" {...{}}>{`* q -> string = a qualification to use when searching for submissions
* include -> string[] = array of properties to include with each submission
* orderBy -> string = comma separated list of submission properties and fields to sort the response by; all parts of the orderBy except the optionally specified timeline must be included in all indexes used by the query.
* direction -> "ASC" | "DESC" = the direction to order the results; defaults to DESC
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "#definekqlquery"
      }}>{`defineKqlQuery`}</a>{` helper function can be used to build the qualification.`}</p>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submissions -> Submission{} = an array of submissions
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { searchSubmissions, defineKqlQuery } from '@kineticdata/react';

const kapp = 'services';

const query = defineKqlQuery()
  .equals('type', 'type')
  .in('values[Status]', 'status')
  .end();

searchSubmissions({
  kapp,
  search: {
    q: query({ type: 'Service', status: ['Active', 'Pending'] }),
    include: ['details', 'values']
  }
}).then(({ submissions }) =>
  console.log(submissions),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "definekqlquery"
    }}>{`defineKqlQuery`}</h2>
    <p>{`The key to searching for submissions is building up the search qualification.
The searchSubmissions API function has a utility for building this up called
`}<inlineCode parentName="p">{`defineKqlQuery`}</inlineCode>{`. The `}<inlineCode parentName="p">{`defineKqlQuery`}</inlineCode>{` function returns a SearchBuilder object
that has a number of chainable methods used to build a query. Calling `}<inlineCode parentName="p">{`.end()`}</inlineCode>{`
on the SearchBuilder object returns a function that can be called with a map of
key-value pairs (where the keys were defined in the chainable methods) to
compile the qualification.`}</p>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const query = defineKqlQuery()
  .equals('type', 'type')
  .in('coreState', 'coreState')
  .or()
  .equals('values[Requested For]', 'username')
  .equals('values[Requested By]', 'username')
  .end()
  .startsWith('values[Status]', 'status')
  .end();
const q = query({
  type: 'Service',
  coreState: ['Draft', 'Submitted'],
  username: 'allan.allbrook',
  status: 'A',
});
`}</code></pre>
    <h4 {...{
      "id": "result"
    }}>{`Result`}</h4>
    <pre><code parentName="pre" {...{}}>{`type = "Service"
AND coreState IN ("Draft", "Submitted")
AND (
  values[Requested For] = "allan.allbrook"
  OR values[Requested By] = "allan.allbrook"
)
AND values[Status] =* "A"
`}</code></pre>
    <h3 {...{
      "id": "methods"
    }}>{`Methods`}</h3>
    <p>{`The value passed into the below methods is a key of type `}<inlineCode parentName="p">{`string`}</inlineCode>{` that will be
used when calling the compiled query function that `}<inlineCode parentName="p">{`defineKqlQuery`}</inlineCode>{` returns.`}</p>
    <p>{`If a method is used and an empty value is provided when calling the compiled
query function, that part of the query will be omitted. If you want empty values
included in the query, you must set the `}<inlineCode parentName="p">{`strict`}</inlineCode>{` parameter in the below
functions to true.`}</p>
    <pre><code parentName="pre" {...{}}>{`and() -> SearchBuilder{}
  = begins an \`and\` context
between(field, minValue, maxValue, strict) -> SearchBuilder{}
  = add a between check
equals(field, value, strict) -> SearchBuilder{}
  = add an equality check
greaterThan(field, value, strict) -> SearchBuilder{}
  = add a greater than check
greaterThanOrEquals(field, value, strict) -> SearchBuilder{}
  = add a greater than or equals check
in(field, values, strict) -> SearchBuilder{}
  = add an \`in\` statement - allows you to pass an array of values
lessThan(field, value, strict) -> SearchBuilder{}
  = add a less than check
lessThanOrEquals(field, value, strict) -> SearchBuilder{}
  = add a less than or equals check
or() -> SearchBuilder{}
  = begins an \`or\` context
startsWith(field, value) -> SearchBuilder{}
  = add a starts with check
end() -> SearchBuilder{} | function
  = closes the last \`and\` or \`or\` context, or if it's the last context, compiles the query and returns the query function
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      